import React from 'react'
import PageWrapper from '../components/PageWrapper'
import MetaData from '../components/MetaData'
import Hero from '../sections/common/Hero'
import Content from '../sections/about/FeaturesContent'
import { Title, Text, Section, BoxItems, SvgMode } from '../components/Core'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Card1IconLight from '../assets/image/svg/custodial.inline.svg'
import Card2IconLight from '../assets/image/svg/nine.inline.svg'
import Card3IconLight from '../assets/image/svg/ic_security.inline.svg'
import Card4IconLight from '../assets/image/svg/multi.inline.svg'
import Card1IconDark from '../assets/image/svg/custodialDark.inline.svg'
import Card2IconDark from '../assets/image/svg/nineDark.inline.svg'
import Card3IconDark from '../assets/image/svg/ic_securityDark.inline.svg'
import Card4IconDark from '../assets/image/svg/multiDark.inline.svg'
import GetStarted from '../sections/landing1/GetStarted'

const FeatureCard = ({ icon, title, className, children, ...rest }) => {
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  return (
      <BoxItems 
        {...rest}
        {...{title}}
        {...{children}} 
        textColor={userDarkMode ? "light" : "dark"}  
        bgColor={userDarkMode ? 'bgDarkGray' : '#F0F0F0'}
      />
  )
}

const Security = () => {
  const { t, ready } = useTranslation(['security'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return ready && (
    <>
      <MetaData page="security" />
      <PageWrapper>
        <Hero
          bg={userDarkMode ? 'black' : 'bg'}
          title={t('title')}
          firstP={t('firstP')}
        >
          {t('subTitle')}
        </Hero>
        <Section bg={userDarkMode ? 'black' : 'bg'} py={0}>
          <Container>
            <Row>
              <FeatureCard title={t('custodial')}>
                <SvgMode
                  Dark={Card1IconDark}
                  Light={Card1IconLight}
                  isDarkMode={userDarkMode}
                />
              </FeatureCard>
              <FeatureCard title={t('uptime')}>
                <SvgMode
                  Dark={Card2IconDark}
                  Light={Card2IconLight}
                  isDarkMode={userDarkMode}
                />
              </FeatureCard>
              <FeatureCard title={t('2fa')}>
                <SvgMode
                  Dark={Card3IconDark}
                  Light={Card3IconLight}
                  isDarkMode={userDarkMode}
                />
              </FeatureCard>
              <FeatureCard title={t('multi')}>
                <SvgMode
                  Dark={Card4IconDark}
                  Light={Card4IconLight}
                  isDarkMode={userDarkMode}
                />
              </FeatureCard>
            </Row>
          </Container>
        </Section>
        <Content
          bullets={true}
          title={t('aBrand')}
          text1={t('lsIs')}
          text2={t('lsHas')}
          bullet1={t('over20')}
          bullet2={t('approvedReg')}
          bullet3={t('exeptional')}
          text5={t('weLook')}
          linkText={t('learnMore')}
          linkUrl="https://www.lightspeed.com/"
        />
        <Content
          customBackground={userDarkMode ? 'bgDarkGray' : 'white'}
          bullets={true}
          title={t('industryLeading')}
          text1={t('custodyProvider')}
          text2={t('primeTrust')}
          text3={t('theyHave')}
          bullet1={t('transacted')}
          bullet2={t('platforms')}
          bullet3={t('apiCalls')}
          intLinkText={t('readOur')}
          intLinkUrl="security/security-practices"
          linkText={t('perPrimeTrust')}
          linkUrl="https://www.primetrust.com/"
        />
        <Content
          bullets={true}
          title={t('wallets')}
          text1={t('thereIs')}
          text2={t('withInstitutional')}
          bullet1={t('lowRisk')}
          bullet2={t('safe')}
          bullet3={t('funds')}
        />
        <GetStarted />
      </PageWrapper>
    </>
  )
}
export default Security
